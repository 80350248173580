import React, { useState } from 'react';
import { ArrowDownCircle } from 'lucide-react';

const App = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <div className="bg-gradient-to-r from-purple-600 to-blue-500 min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="text-left">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-white mb-6">
              原始仓
            </h1>
            <p className="text-xl sm:text-2xl text-white mb-8">
              玩与购都是原始仓，最新潮流娱乐玩法，汇聚各类优质精选好物，潮玩手办、数码产品、服饰鞋包、时尚美妆、生活用品等潮玩资讯一站式平台
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
              <button className="bg-white text-purple-600 font-bold py-3 px-6 rounded-full text-lg hover:bg-gray-100 transition duration-300">
                立即下载
              </button>
              <button 
                className="bg-transparent border-2 border-white text-white font-bold py-3 px-6 rounded-full text-lg hover:bg-white hover:text-purple-600 transition duration-300"
              >
                了解更多
              </button>
            </div>
            <div className="flex items-center text-white">
              <ArrowDownCircle className="mr-2" size={24} />
              <span>向下滑动了解更多</span>
            </div>
          </div>
          <div className="relative">
            <div className="relative z-10 transform -rotate-6 transition-transform duration-300 hover:rotate-0">
              <img src="/screen3.png" alt="原始仓主界面" className="rounded-xl shadow-2xl" />
            </div>
            <div className="absolute top-20 -right-4 z-20 transform rotate-6 transition-transform duration-300 hover:rotate-0">
              <img src="/screen2.png" alt="原始仓主界面" className="rounded-xl shadow-2xl" />
            </div>
            <div className="absolute bottom-0 left-0 z-30 transform -rotate-6 transition-transform duration-300 hover:rotate-0">
              <img src="/screen1.png" alt="原始仓主界面" className="rounded-xl shadow-2xl" />
            </div>
          </div>
        </div>
      </div>

     
        <div className="bg-white min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-purple-600 mb-6">
              APP 应用相关详情和服务信息
            </h2>
            <section className="mb-8">
              <h3 className="text-2xl font-bold mb-4">应用介绍</h3>
              <p className="text-lg text-gray-700">
                原始仓是一款集娱乐与购物于一体的潮流APP，汇聚了各类优质精选好物，让用户在享受娱乐的同时轻松购物。
              </p>
            </section>
            <section className="mb-8">
              <h3 className="text-2xl font-bold mb-4">主要功能</h3>
              <ul className="list-disc pl-5 text-lg text-gray-700">
                <li>潮玩手办展示与购买</li>
                <li>最新数码产品推荐</li>
                <li>时尚服饰与配饰购买</li>
              </ul>
            </section>
            {/* <section className="mb-8">
              <h3 className="text-2xl font-bold mb-4">用户评价</h3>
              <p className="text-lg text-gray-700">
                "原始仓真是太棒了！不仅可以了解最新的潮玩资讯，还能轻松购买到心仪的好物。" - 用户A
              </p>
              <p className="text-lg text-gray-700 mt-4">
                "在原始仓购物体验非常好，推荐给所有喜欢潮玩的朋友。" - 用户B
              </p>
            </section> */}
            {/* <section className="mb-8">
              <h3 className="text-2xl font-bold mb-4">下载方式</h3>
              <p className="text-lg text-gray-700">
                扫描下方二维码或点击下载链接，立即体验原始仓APP的魅力。
              </p>
              <div className="flex flex-col sm:flex-row items-center sm:space-x-4 mt-4">
                <img src="/qrcode.png" alt="下载二维码" className="w-32 h-32" />
                <a 
                  href="/download" 
                  className="bg-purple-600 text-white font-bold py-3 px-6 rounded-full text-lg hover:bg-purple-700 transition duration-300 mt-4 sm:mt-0"
                >
                  下载链接
                </a>
              </div>
            </section> */}
            {/* <section className="mb-8">
              <h3 className="text-2xl font-bold mb-4">联系我们</h3>
              <p className="text-lg text-gray-700">
                如果您有任何问题或需要帮助，请通过以下方式联系我们：
              </p>
              <ul className="list-disc pl-5 text-lg text-gray-700">
                <li>客服邮箱：support@yuanshicang.com</li>
                <li>客服电话：123-456-7890</li>
                <li>微信客服：yuanshicang_service</li>
              </ul>
            </section> */}
          </div>
        </div>
      
    </div>
  );
};

export default App;
